import { getUniversalHelpers } from "../../helpers";
import { LiveGameCustomEvent } from "@ollie-sports/models";

export async function liveGameCustomEvent__client__addCustomEvent(p: {
  customEvent: Omit<LiveGameCustomEvent, "id" | "createdAtMS">;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let customEventId = h.LiveGameCustomEvent.generateId();

  await h.LiveGameCustomEvent.add({ doc: { ...p.customEvent, ...{ id: customEventId, createdAtMS: Date.now() } } });

  return customEventId;
}
// i18n certified - complete
