import { SoccerGameEvent, SoccerGameId, LiveGameCustomEvent } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { SoccerLiveGameTimelineEvent } from "../../soccer-logic";

export function liveGameCustomEvent__client__getCustomEventsSubscription(p: {
  soccerGameId: SoccerGameId;
}): BifrostSubscription<LiveGameCustomEvent[] | undefined> {
  const disposeFns: any[] = [];
  const instance = createBifrostSubscription<LiveGameCustomEvent[] | undefined>({
    dispose: () => disposeFns.forEach(fn => fn())
  });
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const sub = h.LiveGameCustomEvent.querySubscription({
    where: [{ soccerGameId: ["==", p.soccerGameId] }]
  }).subscribe(
    a => {
      instance.nextData(a.docs as LiveGameCustomEvent[]);
    },
    e => {
      instance.nextError(e);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

// i18n certified - complete
