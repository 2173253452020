import { MessageId, LiveGameReactionId, LiveGameCustomEventId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function liveGameCustomEvent__client__editCustomEventText(p: {
  liveGameCustomEventId: LiveGameCustomEventId;
  newText: string;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const entry = await h.LiveGameCustomEvent.getDoc(p.liveGameCustomEventId);

  if (!entry) {
    return;
  }

  await h.LiveGameCustomEvent.update({ id: p.liveGameCustomEventId, doc: { text: p.newText, updatedAtMS: Date.now() } });
}

// i18n certified - complete
