import { MessageId, LiveGameReactionId, LiveGameCustomEventId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function liveGameCustomEvent__client__deleteCustomEvent(p: { liveGameCustomEventId: LiveGameCustomEventId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const entry = await h.LiveGameCustomEvent.getDoc(p.liveGameCustomEventId);

  if (!entry) {
    return;
  }

  const nowMS = Date.now();

  await h.LiveGameCustomEvent.update({
    id: p.liveGameCustomEventId,
    doc: {
      updatedAtMS: nowMS,
      deletedAtMS: nowMS,
      imageUri: h._MagicDeleteValue,
      videoThumbnailUri: h._MagicDeleteValue,
      videoUri: h._MagicDeleteValue
    }
  });
}

// i18n certified - complete
